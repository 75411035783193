import React from 'react';
import { useTranslation } from 'react-i18next';

import FadeInAnimation from 'src/client/components/FadeInAnimation/FadeInAnimation';
import Member from 'src/client/components/Member/Member';

const members: string[] = [
  'drAntalKarolyi',
  'zoltanAntalFalkus',
  'erikHerczeg',
  'annaLabszki',
  'robertOrmodi',
  'zsoltMosolygo',
  'balazsKozma',
  'korneliaKassai',
  'agnesSchubauer',
  'arpadKarpati',
  'andreaFejes',
  'csabaGardonyi',
];

const TheseAreUs: React.FC = () => {
  const { t } = useTranslation('these-are-us');

  return (
    <section id="these-are-us" className="pt-20">
      <div className="container">
        <h2>{t('title')}</h2>
        <div className="flex flex-row flex-wrap justify-center">
          {members.map((fullName: string, index: number) => (
            <FadeInAnimation
              key={index}
              direction="down"
              className="mb-8 lg:mb-12 w-full md:w-1/3 xl:w-1/4"
            >
              <Member fullName={fullName} />
            </FadeInAnimation>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TheseAreUs;
